import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleType } from './shared/enums/user/role-types.enum';
import { RoleGuard } from './shared/guards/role.guard';
import { BlankLayoutComponent } from './shared/layouts/blank-layout/blank-layout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./pages/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'invitation',
    loadChildren: () =>
      import('./pages/invitation/invitation.module').then(m => m.InvitationModule)
  },
  {
    path: 'certificates',
    loadChildren: () =>
      import('./pages/certificates/certificates.module').then(m => m.CertificatesModule)
  },
  {
    path: 'attendance-documents',
    loadChildren: () => 
      import('./pages/attendance-documents/attendance-documents.module').then(m => m.AttendanceDocumentsModule)
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'customer-orders',
        loadChildren: () =>
          import('./pages/customer-org-orders/customer-org-orders.module').then(m => m.CustomerOrgOrdersModule)
      },
      {
        path: 'payment-gate-redirect',
        loadChildren: () =>
          import('./pages/payment-gate-redirect/payment-gate-redirect.module').then(m => m.PaymentGateRedirectModule)
      },
      {
        path: 'offer',
        loadChildren: () =>
          import('./pages/offer/offer.module').then(m => m.OfferModule)
      },
      {
        path: 'ticket',
        loadChildren: () =>
          import('./pages/ticket/ticket.module').then(m => m.TicketModule)
      },
      {
        path: 'test',
        canActivate: [ RoleGuard ],
        canActivateChild: [ RoleGuard ],
        data: { forRoles: [ RoleType.SYSTEM_ADMIN ] },
        loadChildren: () =>
          import('./pages/test/test.module').then(m => m.TestModule)
      },
      {
        path: 'client-section',
        loadChildren: () =>
          import('./pages/client-section/client-section.module').then(m => m.ClientSectionModule)
      }
    ]
  },

  {
    path: 'item',
    loadChildren: () =>
      import('./pages/item/item.module').then(m => m.ItemModule)
  },

  {
    path: ':eventUrlId',
    loadChildren: () =>
      import('./pages/event/event.module').then(m => m.EventModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always', // child routes have access to parent url params automatically
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
